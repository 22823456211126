import React from "react"
import Layout from "../components/layout"
import Banner from "../components/banner"
import ProjectList from "../components/project-list"
import { graphql } from "gatsby"

export default function About({ data }) {

    const bannerUrl = "about-banner.jpg";

  return (
    <Layout>
      <Banner title="About Charlie" imageUrl={bannerUrl}>
      </Banner>

      <section className="section">
        <div className="container">
          <div className="content">
            <div className="columns">
              <div className="column">
                <img src="/charlie-and-adam-2021.jpg"></img>
              </div>
              <div className="column bio">
                <p className="has-dropcap">Freelancing and starting my own business at <a className="has-wavy-underline-navy" href="https://www.belowthefold.ca/">Below the Fold</a> (a small studio my husband and I own) continues to be one of my favourite experiences. We partner with local Canadian businesses that we care about, projects ranging from mom-and-pop shop branding, to an umbrella of multi-site development. All from the comfort of our own home (which I guess is the new 'norm' these days).</p>
                <p className="transition">In 2020 I left my <span className="has-tooltip-multiline has-wavy-underline-peach" data-tooltip="Someone who creates new products and improves existing products that solve key problems for Shopify merchants - products that are accessible, intuitive, and visually appealing.">Senior Product Designer</span> position at <a className="has-wavy-underline-navy" href="https://outofthesandbox.com/">Out of the Sandbox</a> to continue working full-time with my wonderful partner, Kieran. I’ve taught Web Development in the <a className="has-wavy-underline-navy" href="https://algonquindesign.ca/">Graphic Design program</a> at <a className="has-wavy-underline-navy" href="http://algonquincollege.com/">Algonquin College</a>, held Shopify and WordPress setup workshops for colleagues and clients, collaborated on personal projects with my favourite small businesses, and still manage to make time for video games.</p>
                <div className="contact-me">
                  <p className="is-size-2 title">Contact me</p>
                  <a className="has-wavy-underline-navy" href="mailto:hey@charliequinn.ca">hey@charliequinn.ca</a>
                  <a className="has-wavy-underline-navy" href="https://www.instagram.com/notenoughquinn/">Instagram</a>                  
                  <a className="has-wavy-underline-navy" href="https://www.linkedin.com/in/notenoughquinn/">LinkedIn</a>                  
                  <a className="has-wavy-underline-navy" href="https://twitter.com/notenoughquinn">Twitter</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>    
    </Layout>
  );
}